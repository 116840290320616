import { isArray } from "lodash-es";
import { format } from "date-fns";
import { AppField, AppStructureFieldParams, ID, SimpleRecord, Tag } from "@smartsuite/types";
import { getFieldLogic } from "@smartsuite/fields-logic/lib/field-type-registry/fields-logic-registry";
import { formatDisplayDuration } from "@smartsuite/fields-logic/lib/utils/duration";
import { BaseRecord } from "../../../../../../../../../types/form";

export const getDisplayValue = (
  record: SimpleRecord,
  fieldSlug: string,
  fieldsToShow: { [key: string]: AppField },
  tags?: Tag[],
  relatedRecords?: BaseRecord[]
): string | null => {
  if (fieldSlug.includes(".")) {
    const [mainFieldSlug, nestedFieldSlug] = fieldSlug.split(".");
    if (fieldsToShow[fieldSlug]?.field_type === "fullnamefield") {
      const recordValue = record?.[mainFieldSlug]?.[nestedFieldSlug];

      if (nestedFieldSlug === "title" && recordValue) {
        const fullNameField = fieldsToShow[fieldSlug];
        const titleChoices = fullNameField.params.choices;

        return titleChoices?.find((choice) => choice.value === recordValue)?.label ?? null;
      }

      return recordValue;
    }

    if (fieldsToShow[fieldSlug]?.field_type === "duedatefield") {
      if (record?.[mainFieldSlug]?.[nestedFieldSlug]?.date) {
        return formatDateTime(
          record?.[mainFieldSlug]?.[nestedFieldSlug]?.date,
          !!record?.[mainFieldSlug]?.[nestedFieldSlug]?.include_time
        );
      } else {
        return null;
      }
    }

    if (fieldsToShow[fieldSlug]?.field_type === "dependencyfield") {
      if (record?.[mainFieldSlug]?.[nestedFieldSlug]) {
        return record?.[mainFieldSlug]?.[nestedFieldSlug]
          .map(
            (item: { record: string }) =>
              relatedRecords?.find((record) => record.id === item.record)?.title || ""
          )
          .join(", ");
      } else {
        return null;
      }
    }

    return record?.[mainFieldSlug]?.[nestedFieldSlug];
  }

  const field = fieldsToShow[fieldSlug];

  return record[fieldSlug]
    ? getFieldValue(record[fieldSlug], fieldSlug, field, tags, relatedRecords)
    : null;
};

const getFieldValue = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordValue: { [key: string]: any },
  fieldSlug: string,
  field: AppField,
  tags: Tag[] = [],
  relatedRecords?: BaseRecord[]
): string | null => {
  const fieldLogic = getFieldLogic(field.field_type);

  switch (field.field_type) {
    case "linkedrecordfield":
    case "userfield":
      return recordValue
        ?.reduce((acc: string[], relatedRecordID: ID) => {
          const relatedRecord = relatedRecords?.find(
            (relatedRecord) => relatedRecord.id === relatedRecordID
          );

          if (!relatedRecord) {
            return acc;
          }

          return [...acc, relatedRecord.title];
        }, [])
        .join(", ");
    case "buttonfield":
      return (field.params as AppStructureFieldParams & { button_label: string }).button_label;
    case "socialnetworkfield":
      return "socialnetworkfield";
    case "tagsfield":
      const tagsValue = recordValue as string[];
      return `${tagsValue.map((tagValue) => tags.find((tag) => tag.id === tagValue)?.label)}`;
    case "checklistfield":
      const totalItems = recordValue?.total_items ?? 0;
      const completedItems = recordValue?.completed_items ?? 0;
      const progress = Math.round(totalItems > 0 ? (completedItems / totalItems) * 100 : 0);
      return `${completedItems} of ${totalItems} (${progress}%)`;
    case "currencyfield":
      const value = Number(recordValue);
      return !isNaN(value)
        ? `${Number(recordValue).toFixed(field.params?.precision ?? 2)} ${field.params?.currency}`
        : null;
    case "daterangefield":
    case "duedatefield":
      if (recordValue?.from_date && recordValue?.to_date) {
        if (!recordValue?.from_date?.date) {
          return formatDateTime(recordValue?.to_date?.date, recordValue?.to_date?.include_time);
        }

        return `${formatDateTime(
          recordValue?.from_date?.date,
          recordValue?.from_date?.include_time
        )} - ${formatDateTime(recordValue?.to_date?.date, recordValue?.to_date?.include_time)}`;
      } else {
        return null;
      }
    case "firstcreatedfield":
    case "lastupdatedfield":
      return formatDateTime(recordValue?.on, true);
    case "subitemsfield":
      return recordValue?.count ?? 0;
    case "timetrackingfield":
      return recordValue?.total_duration
        ? formatDisplayDuration(recordValue.total_duration, "compact", "hh:mm")
        : null;
    case "votefield":
      return recordValue?.total_votes?.toString();
    case "datefield":
      return recordValue?.date
        ? formatDateTime(recordValue?.date, !!recordValue?.include_time)
        : null;
    case "dependencyfield":
      return `Pred...: ${recordValue?.predecessor.length} Suc...: ${recordValue?.successor.length}`;
    case "lookupfield":
    case "rollupfield":
      const targetFieldStructure = field.params?.target_field_structure;
      if (targetFieldStructure) {
        if (isArray(recordValue)) {
          return recordValue
            .flat()
            .map((currentValue) => {
              return getFieldValue(
                currentValue,
                fieldSlug,
                targetFieldStructure,
                tags,
                relatedRecords
              );
            })
            .join(", ");
        }
        return getFieldValue(recordValue, fieldSlug, targetFieldStructure, tags, relatedRecords);
      } else {
        return null;
      }
    default:
      return fieldLogic.getAggregateDisplayValue(recordValue, field);
  }
};

const formatDateTime = (date: string, includeTime: boolean): string => {
  const parsedDate = new Date(date);
  return format(parsedDate, includeTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd");
};
